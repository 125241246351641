import {
  Group,
  Stack,
  Tabs,
  Title,
  Image,
  TextInput,
  Box,
  SimpleGrid,
  Button,
  LoadingOverlay,
  Grid,
} from "@mantine/core";
import { useLoaderData, useOutletContext } from "@remix-run/react";
import { ComfyUIApiClient } from "@stable-canvas/comfyui-client";
import { useCallback, useEffect, useRef, useState } from "react";
import Webcam, { WebcamProps } from "react-webcam";
import { v7 } from "uuid";
import { COMFY_TOKEN, COMFY_URL } from "~/config";
import { usePrompt } from "../hooks/usePrompt";
import { json } from "@remix-run/cloudflare";
import _ from "lodash";
import { Prompt } from "~/libs/type";
import { FunctionArgs } from "~/load-context";
import { requireLogin } from "~/.server/guards";
import { useCounter, useInterval, useTimeout } from "@mantine/hooks";
export const loader = async (args: FunctionArgs) => {
  // await requireLogin(args);
  const referenceImages =
    await args.context.DB.query.REFERENCE_IMAGE.findMany();
  const config = await args.context.DB.query.CONFIG.findFirst();
  return json({ referenceImages, config });
};

const audioConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
};
export default function Vid2Vid() {
  const { client } = useOutletContext<{ client: ComfyUIApiClient }>();

  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [referenceImage, setReferenceImage] = useState<string>("");
  const [style, setStyle] = useState<string>("");
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [filename, setFilename] = useState<string>("");
  const [uploading, setUploading] = useState(false);
  const [running, setRunning] = useState(false);
  const [outputVideo, setOutputVideo] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const outputVideoRef = useRef<HTMLVideoElement>(null);
  const [countdown, countdownHandler] = useCounter(5);

  const interval = useInterval(() => {
    console.log("interval", countdown);
    countdownHandler.decrement();
  }, 1000);

  const timeout = useTimeout(() => {
    handleStopCaptureClick();
    countdownHandler.reset();
    interval.stop();
  }, 5 * 1000);

  const [videoConstraints, setVideoConstraints] = useState({
    width: 512,
    height: 512,
    facingMode: "user",
    frameRate: 12,
  });

  const data = useLoaderData<typeof loader>();

  const buildFinalPrompt = useCallback(() => {
    const replaced: Prompt = JSON.parse(data.config?.vid2vidFlow!);
    _.forEach(replaced, (value, key) => {
      if (value._meta.title === "ReferenceImage") {
        value.inputs.image = referenceImage;
      }
      if (value._meta.title === "Style") {
        value.inputs.style = style;
      }
      if (value._meta.title === "Video") {
        value.inputs.video = filename;
      }
      if (value.class_type.startsWith("KSampler")) {
        value.inputs.seed = Math.floor(Math.random() * 1000000000);
      }
    });
    return replaced;
  }, [style, referenceImage, filename]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current!.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleStartCapture = useCallback(() => {
    if (webcamRef.current) {
      console.log("handleStartCapture");
      setCapturing(true);
      webcamRef.current.stream?.addEventListener("inactive", () => {
        console.log("inactive");
      });
      const supportMp4 = MediaRecorder.isTypeSupported("video/mp4");
      const mimeType = supportMp4 ? "video/mp4" : "video/webm";
      console.log("mimeType", mimeType);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream!, {
        mimeType,
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      interval.start();
      timeout.start();
    }
  }, [
    webcamRef.current,
    setCapturing,
    mediaRecorderRef.current,
    countdownHandler,
    handleStopCaptureClick,
    timeout,
    interval,
  ]);

  const handleDataAvailable = useCallback(
    (ev: MediaRecorderEventMap["dataavailable"]) => {
      console.log("dataavailable", ev.data.size);
      if (ev.data.size > 0) {
        setRecordedChunks((prev) => prev.concat(ev.data));
      }
    },
    [setRecordedChunks]
  );

  useEffect(() => {
    if (!recordedChunks.length) return;
    (async () => {
      console.log("recordedChunks", recordedChunks);
      const id = v7();
      const mimeType = MediaRecorder.isTypeSupported("video/mp4")
        ? "video/mp4"
        : "video/webm";
      const extension = mimeType.split("/")[1];
      const filename = `video-${id}.${extension}`;
      const videoFile = new File(recordedChunks, filename, {
        type: mimeType,
      });

      const formData = new FormData();
      formData.append("image", videoFile);
      console.log("uploading", formData);
      setUploading(true);
      await client
        .fetchApi("/upload/image", {
          body: formData,
          method: "POST",
        })
        .finally(() => {
          setUploading(false);
          setFilename(filename);
          setRecordedChunks([]);
        });
    })();
  }, [recordedChunks]);

  useEffect(() => {
    if (!referenceImage && data.referenceImages.length > 0) {
      setReferenceImage(data.referenceImages[0].filename);
    }
  }, [data]);

  useEffect(() => {
    if (!client) {
      return;
    }
    const clear = client.on("executed", async (data) => {
      console.log("executed", data);
      if (data.output.gifs) {
        setOutputVideo(data.output.gifs[0].filename);
        setRunning(false);
      }
    });
    //@ts-ignore
    const clearSuccess = client.on("execution_success", (data) => {
      setRunning(false);
    });
    return () => {
      clear();
      clearSuccess();
    };
  }, [client]);
  return (
    <Tabs.Panel value="vid2vid" keepMounted>
      <LoadingOverlay visible={uploading || running} />
      <Stack>
        <Title order={3}>Video to Video</Title>
        <Group>
          {data.referenceImages.map((image, index) => (
            <Image
              bd={
                referenceImage === image.filename
                  ? "2px solid red"
                  : "2px solid transparent"
              }
              onClick={() => setReferenceImage(image.filename)}
              w={75}
              h={75}
              src={`${COMFY_URL}/view?filename=${image.filename}&type=input&token=${COMFY_TOKEN}`}
              key={index}
            />
          ))}
        </Group>
        <TextInput
          label="Style"
          value={style}
          onChange={(e) => setStyle(e.target.value)}
        ></TextInput>
        <Grid>
          <Grid.Col span={12}>
            <Group>
              <Button
                size="xs"
                disabled={capturing}
                onClick={handleStartCapture}
              >
                {capturing ? countdown : "Capture"}
              </Button>
              <Button
                size="xs"
                disabled={!filename}
                variant="outline"
                onClick={() => {
                  setRunning(true);
                  client.enqueue(buildFinalPrompt(), {
                    progress: (progress) => {
                      console.log(progress);
                    },
                    resolver: (res) => {
                      console.log("resolved", res);
                      setRunning(false);
                      return res;
                    },
                  });
                }}
              >
                Transform
              </Button>
              <Button
                size="xs"
                variant="outline"
                onClick={() => {
                  setVideoConstraints({
                    ...videoConstraints,
                    facingMode:
                      videoConstraints.facingMode === "user"
                        ? "environment"
                        : "user",
                  });
                }}
              >
                Toggle Camera
              </Button>
            </Group>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6 }}>
            <Webcam
              videoConstraints={videoConstraints}
              audioConstraints={audioConstraints}
              ref={webcamRef}
              audio={false}
              width={"100%"}
            ></Webcam>
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 3 }}>
            {filename && (
              <Box>
                <video
                  ref={videoRef}
                  loop
                  autoPlay
                  muted
                  width={"100%"}
                  src={`https://comfyui.by-air.xyz/view?filename=${filename}&type=input&token=${COMFY_TOKEN}`}
                ></video>
              </Box>
            )}
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 3 }}>
            {outputVideo && (
              <Box>
                <video
                  ref={outputVideoRef}
                  loop
                  autoPlay
                  onPlay={() => {
                    if (outputVideoRef.current) {
                      outputVideoRef.current.currentTime = 0;
                    }
                    if (videoRef.current) {
                      videoRef.current.currentTime = 0;
                    }
                  }}
                  muted
                  width={"100%"}
                  src={`https://comfyui.by-air.xyz/view?filename=${outputVideo}&type=output&token=${COMFY_TOKEN}`}
                ></video>
              </Box>
            )}
          </Grid.Col>
        </Grid>
      </Stack>
    </Tabs.Panel>
  );
}
